<template>
  <div class="card" :class="{'card--scrollable': scrollable}">
      <div class="card__inner">
        <slot></slot>
      </div>
  </div>
</template>

<script>
export default {
    props: {
       scrollable: {
        type: Boolean,
       }
    },
    setup() {

    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/base/mixins";
.card {
    background-color: #ffffff;
    overflow: hidden;
    &--scrollable {
        // @include lg-up {
        //     max-height: 400px;
        //     height: 100%;
        // }
        max-height: 400px;
        height: 100%;
    }
    &__inner {
        padding: 1.5rem;
        overflow-y: auto;
        height: 100%;
    }
}
</style>